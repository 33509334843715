import { PlusIcon } from '@heroicons/react/24/outline'
import type { FindIntegrations } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as EmptyComponent } from 'src/components/Empty/Empty'
import Button from 'src/components/Library/Button/Button'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import Integrations from 'src/components/SuperAdmin/Integrations/Integrations'

export const QUERY = gql`
  query FindIntegrations {
    integrations {
      id
      active
      createdAt
      updatedAt
      clientId
      apiAccountKey
      apiKey
      apiSecret
      integrationType
      portfolioName
      integrationName
      client {
        id
        name
      }
    }
    clients {
      id
      name
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => {
  return (
    <div className="grid h-full max-h-[calc(100vh-65px)] place-items-center">
      <div className="rw-text-center">
        <EmptyComponent defaultIcon title="No Integrations yet." />
        <Button
          className="mt-4"
          startIcon={<PlusIcon className="h-4 w-4" />}
          onClick={() => {
            navigate(routes.integrations())
          }}
        >
          Create Integration
        </Button>
      </div>
    </div>
  )
}

export const Failure = ({ error }: CellFailureProps) => (
  <div className="rw-cell-error">{error.message}</div>
)

export const Success = ({
  integrations,
  clients,
}: CellSuccessProps<FindIntegrations>) => {
  return <Integrations integrations={integrations} clients={clients} />
}
