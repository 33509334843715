import React, { useEffect, useState } from 'react'

import { Box, Grid2 as Grid, InputLabel, TextField } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import { captureException } from '@sentry/browser'
import Select from 'react-select'
import {
  CreateIntegrationMutation,
  CreateIntegrationMutationVariables,
  FindIntegrations,
  UpdateIntegrationMutation,
  UpdateIntegrationMutationVariables,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button/Button'
import Modal from 'src/components/Modal/Modal'
import { QUERY } from 'src/components/SuperAdmin/Integrations/IntegrationsCell'

const CREATE_INTEGRATION_MUTATION = gql`
  mutation CreateIntegrationMutation($input: CreateIntegrationInput!) {
    createIntegration(input: $input) {
      id
    }
  }
`

const UPDATE_INTEGRATION_MUTATION = gql`
  mutation UpdateIntegrationMutation(
    $id: Int!
    $input: UpdateIntegrationInput!
  ) {
    updateIntegration(id: $id, input: $input) {
      id
      active
      createdAt
      updatedAt
      clientId
      apiAccountKey
      apiKey
      apiSecret
      integrationType
      integrationName
      portfolioName
    }
  }
`

export enum IntegrationType {
  AGENTBOX = 'AGENTBOX',
  AIRTABLE = 'AIRTABLE',
  PME = 'PME',
  REX = 'REX',
  PT = 'PT',
  VRE = 'VRE',
  HS = 'HS',
  TIMEDOCTOR = 'TIMEDOCTOR',
  BASEROW = 'BASEROW',
  COMPARECONNECT = 'COMPARECONNECT',
  INNOVAYT = 'INNOVAYT',
  RELLO = 'RELLO',
  ONCOVER = 'ONCOVER',
  RAYWHITEHOMENOW = 'RAYWHITEHOMENOW',
  YOURPORTER = 'YOURPORTER',
  PRDCONNECT = 'PRDCONNECT',
  THEWHOLEHOUSE = 'THEWHOLEHOUSE',
}

interface KeyValues {
  ACCOUNT_KEY: string
  API_KEY: string
  API_SECRET: string
}

const integrationOptions = [
  { value: IntegrationType.AIRTABLE, label: 'Airtable' },
  { value: IntegrationType.AGENTBOX, label: 'Agentbox' },
  { value: IntegrationType.PME, label: 'PropertyMe' },
  { value: IntegrationType.REX, label: 'Rex' },
  { value: IntegrationType.PT, label: 'Property Tree' },
  { value: IntegrationType.VRE, label: 'Vault Real Estate' },
  { value: IntegrationType.HS, label: 'Hubspot' },
  { value: IntegrationType.TIMEDOCTOR, label: 'Time Doctor' },
  { value: IntegrationType.BASEROW, label: 'Baserow' },
  { value: IntegrationType.COMPARECONNECT, label: 'Compare Connect' },
  { value: IntegrationType.RELLO, label: 'Rello' },
  { value: IntegrationType.ONCOVER, label: 'OnCover' },
  { value: IntegrationType.INNOVAYT, label: 'Innovayt' },
]
const authTypes = ['API_KEY', 'API_SECRET', 'ACCOUNT_KEY']

const authAllocations = {
  AIRTABLE: {
    API_KEY: {
      label: 'Access Token',
    },
  },
  HS: {
    API_KEY: {
      label: 'Access Token',
    },
  },
  COMPARECONNECT: {
    API_KEY: {
      label: 'API Key',
    },
  },
  ONCOVER: {
    API_KEY: {
      label: 'API Key',
    },
  },
  RELLO: {
    API_KEY: {
      label: 'API Key',
    },
    API_SECRET: {
      label: 'Merchant Id',
    },
  },
  INNOVAYT: {},
  PME: {
    API_KEY: {
      label: 'Client Id',
    },
    API_SECRET: {
      label: 'Client Secret',
    },
    ACCOUNT_KEY: {
      label: 'Refresh Token',
    },
  },
  REX: {
    API_KEY: {
      label: 'Username',
    },
    API_SECRET: {
      label: 'Password',
    },
    ACCOUNT_KEY: {
      label: 'Rex Account Id',
    },
  },
  TIMEDOCTOR: {
    API_KEY: {
      label: 'Username',
    },
    API_SECRET: {
      label: 'Password',
    },
    ACCOUNT_KEY: {
      label: 'Company Id',
    },
  },
  AGENTBOX: {
    API_KEY: {
      label: 'Account Id',
    },
    ACCOUNT_KEY: {
      label: 'Account Key',
    },
  },
  PT: {
    API_KEY: {
      label: 'API Key',
    },
    API_SECRET: {
      label: 'Company Id',
    },
    ACCOUNT_KEY: {
      label: 'Application Id',
    },
  },
  VRE: {
    API_KEY: {
      label: 'Vault Token',
    },
  },
  BASEROW: {
    API_KEY: {
      label: 'Username',
    },
    API_SECRET: {
      label: 'Password',
    },
  },
}

type IntegrationsQueryType = FindIntegrations['integrations']

interface NewIntegrationProps {
  open: boolean
  handleClose: () => void
  clients: FindIntegrations['clients']
  integration?: IntegrationsQueryType[0]
}

const NewIntegration: React.FC<NewIntegrationProps> = ({
  open,
  handleClose,
  clients,
  integration = null,
}) => {
  const [isActive, setIsActive] = useState(false)
  const [client, setClient] = useState(null)
  const [clientOptions, setClientOptions] = useState<
    { value: number; label: string }[]
  >([])
  const [integrationName, setIntegrationName] = useState('')
  const [portfolioName, setPortfolioName] = useState('')
  const [IntegrationTypeSelected, setIntegrationTypeSelected] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [keyValues, setKeyValues] = useState<KeyValues>(() => {
    const initialKeyValues: KeyValues = {
      ACCOUNT_KEY: '',
      API_KEY: '',
      API_SECRET: '',
    }
    return initialKeyValues
  })

  const handleSelectClientChange = (selectedOption) => {
    setClient(selectedOption)
  }

  const handleSelectIntegrationTypeSelectedChange = (selectedOption) => {
    // Assuming selectedOption is an enum value (e.g., IntegrationType.AIRTABLE)
    setIntegrationTypeSelected(selectedOption)
  }

  const handleAssignKey = (key, value) => {
    const newKeyValues = { ...keyValues }
    newKeyValues[key] = value
    setKeyValues(newKeyValues)
  }

  const [createIntegration, { loading: loadingCreate, error: errorCreate }] =
    useMutation<CreateIntegrationMutation, CreateIntegrationMutationVariables>(
      CREATE_INTEGRATION_MUTATION,
      {
        onCompleted: () => {
          toast.success('Integration created')
          handleClose()
        },
        onError: (error) => {
          toast.error(error.message)
          captureException(error)
        },
        refetchQueries: [{ query: QUERY }],
        awaitRefetchQueries: true,
      },
    )

  const [updateIntegration, { loading, error }] = useMutation<
    UpdateIntegrationMutation,
    UpdateIntegrationMutationVariables
  >(UPDATE_INTEGRATION_MUTATION, {
    onCompleted: () => {
      toast.success('Integration updated')
      navigate(routes.integrations())
    },
    onError: (error) => {
      toast.error(error.message)
    },
    refetchQueries: [{ query: QUERY }],
    awaitRefetchQueries: true,
  })

  const handleUpsert = () => {
    setErrorText('')
    if (!client) {
      setErrorText('Client is required')
      return
    }
    if (integrationName === '') {
      setErrorText('Integration Name is required')
      return
    }
    if (portfolioName === '') {
      setErrorText('Portfolio Name is required')
      return
    }
    if (!IntegrationTypeSelected) {
      setErrorText('Integration Type is required')
      return
    }

    const castInput = {
      active: isActive,
      apiAccountKey: keyValues.ACCOUNT_KEY,
      apiKey: keyValues.API_KEY,
      apiSecret: keyValues.API_SECRET,
      clientId: client.value,
      integrationName: integrationName,
      portfolioName: portfolioName,
      integrationType: IntegrationTypeSelected.value,
    }
    !integration
      ? createIntegration({ variables: { input: castInput } })
      : updateIntegration({
          variables: { id: integration.id, input: castInput },
        })
    handleClose()
  }

  useEffect(() => {
    if (clients) {
      setClientOptions(
        clients.map((client) => ({
          value: client.id,
          label: client.name,
        })),
      )
    }
  }, [clients])

  useEffect(() => {
    setIsActive(integration ? integration.active : false)
    setClient(
      integration
        ? clientOptions?.find(
            (client) => client?.value === integration.clientId,
          )
        : null,
    )
    setIntegrationName(integration ? integration.integrationName : '')
    setPortfolioName(integration ? integration.portfolioName : '')

    setIntegrationTypeSelected(
      integration
        ? integrationOptions?.find(
            (integ) => integration?.integrationType === integ.value,
          )
        : null,
    )

    setIsSaving(false)
    setErrorText('')
  }, [integration, open, clientOptions])

  useEffect(() => {
    const initialKeyValues: KeyValues = {
      ACCOUNT_KEY: '',
      API_KEY: '',
      API_SECRET: '',
    }
    setKeyValues(initialKeyValues)
  }, [client, IntegrationTypeSelected, integration, open, clientOptions])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`${integration ? 'Edit ' : 'Create '} Integration`}
    >
      <>
        <Box
          sx={{
            minHeight: '700px', // Adjust the value to your desired minimum height
            display: 'flex',
            flexDirection: 'column', // Make the Box a flex container with a column layout
            padding: '1rem',
          }}
        >
          <Stack
            justifyContent="space-between"
            sx={{
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 12 }}>
                <Stack padding={'20px'}>
                  <Grid container spacing={2}>
                    <Grid
                      size={{ xs: 4, sm: 4 }}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <InputLabel>Set Active</InputLabel>
                    </Grid>
                    <Grid size={{ xs: 8, sm: 8 }}>
                      <Checkbox
                        checked={isActive}
                        onClick={() => {
                          setIsActive(!isActive)
                        }}
                      />
                    </Grid>
                    <Grid
                      size={{ xs: 4, sm: 4 }}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <InputLabel>Client</InputLabel>
                    </Grid>
                    <Grid size={{ xs: 8, sm: 8 }}>
                      {!integration ? (
                        <Select
                          options={clientOptions}
                          value={client}
                          onChange={handleSelectClientChange}
                        />
                      ) : (
                        <InputLabel>{client?.label}</InputLabel>
                      )}
                    </Grid>
                    {client && (
                      <>
                        <Grid
                          size={{ xs: 4, sm: 4 }}
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <InputLabel>Integration Name</InputLabel>
                        </Grid>

                        <Grid size={{ xs: 8, sm: 8 }}>
                          <TextField
                            type="text"
                            value={integrationName}
                            placeholder={''}
                            onChange={(e) => setIntegrationName(e.target.value)}
                            fullWidth
                            size="small"
                          />
                        </Grid>
                        <Grid
                          size={{ xs: 4, sm: 4 }}
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <InputLabel>Portfolio Name</InputLabel>
                        </Grid>
                        <Grid size={{ xs: 8, sm: 8 }}>
                          <TextField
                            type="text"
                            value={portfolioName}
                            placeholder={''}
                            onChange={(e) => setPortfolioName(e.target.value)}
                            fullWidth
                            size="small"
                          />
                        </Grid>

                        <Grid
                          size={{ xs: 4, sm: 4 }}
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <InputLabel>Integration Type</InputLabel>
                        </Grid>
                        <Grid size={{ xs: 8, sm: 8 }}>
                          <Select
                            options={integrationOptions}
                            value={IntegrationTypeSelected}
                            onChange={handleSelectIntegrationTypeSelectedChange}
                          />
                        </Grid>

                        {IntegrationTypeSelected &&
                          authTypes.map((authType) => (
                            <React.Fragment key={authType}>
                              {authAllocations[IntegrationTypeSelected.value] &&
                                Object.prototype.hasOwnProperty.call(
                                  authAllocations[
                                    IntegrationTypeSelected.value
                                  ],
                                  authType,
                                ) && (
                                  <>
                                    <Grid
                                      size={{ xs: 4, sm: 4 }}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <InputLabel>
                                        {`New ${
                                          authAllocations[
                                            IntegrationTypeSelected.value
                                          ][authType].label
                                        }`}
                                      </InputLabel>
                                    </Grid>
                                    <Grid size={{ xs: 8, sm: 8 }}>
                                      <TextField
                                        type="password"
                                        label=""
                                        value={keyValues[authType]}
                                        placeholder={''}
                                        onChange={(e) =>
                                          handleAssignKey(
                                            authType,
                                            e.target.value,
                                          )
                                        }
                                        fullWidth
                                        size="small"
                                      />
                                    </Grid>
                                  </>
                                )}
                            </React.Fragment>
                          ))}
                      </>
                    )}
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction={'row'}
            spacing={2}
            sx={{ justifyContent: 'flex-end' }}
          >
            <InputLabel
              sx={{
                color: 'red',
                fontSize: '12px',
                textAlign: 'right',
                width: '100%',
              }}
            >
              {errorText}
            </InputLabel>

            <Button
              fullWidth={false}
              variant="outlined"
              onClick={handleClose}
              loading={isSaving}
            >
              Cancel
            </Button>
            <Button
              fullWidth={false}
              variant="contained"
              onClick={() => {
                // setIsSaving(true)
                handleUpsert()
              }}
              loading={isSaving}
            >
              {integration ? 'Save' : 'Insert'}
            </Button>
          </Stack>
        </Box>
      </>
    </Modal>
  )
}

export default NewIntegration
